import * as React from 'react'
import { useSelector } from 'react-redux'
import { ResortSectionRow } from '@components/resort-section-row'
import { Resort } from '@models/app-data'
import Carousel, { ResponsiveType } from 'react-multi-carousel'
import { RootState } from '@store/index'
import * as clsx from 'clsx'

const breakpoints = {
  desktop: { breakpoint: { max: 3000, min: 1024 }, items: 4, slidesToSlide: 1 },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 2, slidesToSlide: 1 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 1, slidesToSlide: 1 },
}

interface Props {
  title: string
  subtitle?: React.ReactNode
  wrapperClassName?: string
  customBreakpoints?: ResponsiveType
  withUpcomingInvestments?: boolean
  resortsIdToShow?: number[]
}

export const ResortsGallery = React.forwardRef(
  (
    {
      title,
      subtitle,
      wrapperClassName,
      customBreakpoints,
      withUpcomingInvestments = true,
      resortsIdToShow = [2, 1, 5, 8, 6, 7, 9],
    }: Props,
    ref: React.RefObject<HTMLDivElement>,
  ): JSX.Element => {
    const resorts = useSelector((state: RootState) => state.appDataState.appData.resorts)

    const resortsToShow = resorts.filter(resort => resortsIdToShow.includes(resort.id))

    const elementsToShow = [
      ...resortsToShow.map((resort: Resort) => <ResortSectionRow key={resort.id} resort={resort} />),
      ...(withUpcomingInvestments
        ? [
            <div
              key="upcoming-investment"
              className="p-5 bg-white flex-fill d-flex align-items-center justify-content-center flex-column package-wholesale__resort"
              style={{ minHeight: 265 }}
            >
              <img src={require('../../assets/images/icons_correct.svg')} height="50" alt="Nadchodząca inwestycja" />
              <div className="p-3 font-xl text-dark-blue font-700 mx-5 text-center">Już wkrótce kolejna inwestycja</div>
            </div>,
          ]
        : []),
    ]

    return (
      <div className={clsx('container px-0', wrapperClassName)}>
        <div className="package-wholesale__resorts card col-lg-9 col-12 card-shadow" ref={ref}>
          <div className="package-wholesale__resorts__title">
            <strong className="font-xxl">{title}</strong>
            {subtitle ?? (
              <p>
                Mamy aż 7 lokalizacji do wyboru <strong>nad morzem lub w górach</strong>
              </p>
            )}
          </div>
          <Carousel
            showDots
            responsive={customBreakpoints ?? breakpoints}
            arrows={false}
            autoPlay={false}
            shouldResetAutoplay={false}
            itemClass="package-wholesale__resorts__item"
          >
            {elementsToShow}
          </Carousel>
        </div>
      </div>
    )
  },
)
