import * as React from 'react'
import { CardContentBase } from '@components/card-content-base'
import { GastroCardPrices } from '@modules/package-wholesale/details/gastro-card/gastro-card-prices'
import { CircleCheck } from '@components/circle-check'
import { Alert } from '@components/alert'
import { RefObject } from 'react'
import { getGastroProductPrice } from '@modules/gastro-card/details/utils'
import { asDecimal } from '@helpers/utils'
import { GastroGenericProductDetails, GastroProduct } from '@modules/gastro-generic/models'
import { GastroGenericPreview } from '@modules/gastro-generic/details/gastro-generic-preview'
import { getProductData } from '@modules/gastro-generic/gastro-generic-data'

interface Props {
  gastroGenericDetails: GastroGenericProductDetails
  gastroProduct: GastroProduct
  onScrollToPayment: () => void
}

export const GastroGenericContent = React.forwardRef(
  ({ gastroGenericDetails, onScrollToPayment, gastroProduct }: Props, ref: RefObject<HTMLDivElement>): JSX.Element => {
    const { sellPrice, bonusPrice } = getGastroProductPrice(gastroGenericDetails)
    const { productName, benefitsPoints, benefitsList, alertText } = getProductData(gastroGenericDetails, gastroProduct)

    const basicPrice = sellPrice.gt(asDecimal(0))
      ? gastroGenericDetails.sell_price
      : gastroGenericDetails.declared_sell_price

    return (
      <div>
        <CardContentBase
          title={gastroGenericDetails.is_paid ? 'Potwierdzenie zakupu produktu' : 'Podsumowanie zamówienia'}
          client={gastroGenericDetails?.client}
          seller={gastroGenericDetails?.seller}
          created={gastroGenericDetails?.created || ''}
          isProductPaid={gastroGenericDetails.is_paid}
          onScrollToPayment={onScrollToPayment}
          productInvoice={gastroGenericDetails}
        />
        <hr className="mx-n5" />
        <div className="col-xl-9 col-lg-10 text-darker-gray mt-3 mb-4 font-md" ref={ref}>
          <strong className="text-secondary font-xxl">Twój Voucher "{productName}"</strong>
          <GastroGenericPreview basicPrice={basicPrice} bonusPrice={bonusPrice} />
          <GastroCardPrices basicPrice={basicPrice} bonusPrice={bonusPrice} />
          <strong className="d-block mt-4">Cechy produktu:</strong>
          <div className="mt-2 row">
            {benefitsPoints.map(benefit => (
              <CircleCheck key={benefit} className="col-lg-6 mb-1 mb-lg-0">
                <span className="font-md lh-initial">
                  <span className="text-muted">{benefit} </span>
                </span>
              </CircleCheck>
            ))}
          </div>
          <ul className="ps-0 mt-4 col-md-9 col-12">
            {benefitsList.map(benefit => (
              <li key={benefit}>{benefit}</li>
            ))}
          </ul>
        </div>
        <div className="font-md text-darker-gray">
          <Alert variant="info" icon="uil-info-circle text-primary font-xl" className="mb-4">
            {alertText}
          </Alert>
        </div>
      </div>
    )
  },
)
