import * as React from 'react'
import { Checkbox } from '@components/controls/checkbox'
import { useFormContext, useWatch } from 'react-hook-form'
import { AvailableRuleKeyword } from '@models/app-data'
import { useEffect } from 'react'
import { MARKETING_RULES_KEYWORDS, MarketingAgreementSection } from '@components/agreement/marketing-agreement-section'

interface Props {
  className?: string
  children?: React.ReactNode
  additionalRulesNames?: string[]
  productRules?: AvailableRuleKeyword[]
  withMarketingRules?: boolean
  title?: string
  selectAllLabel?: string
  fullWidth?: boolean
}

export const AgreementSection = ({
  className,
  children,
  additionalRulesNames = [],
  productRules,
  withMarketingRules = true,
  title = 'Akceptacja regulaminów i zasad promocji:',
  selectAllLabel = 'Zaznacz wszystkie',
  fullWidth,
}: Props): JSX.Element | null => {
  const { setValue, control } = useFormContext()

  const rulesToCheck = [...additionalRulesNames, ...(productRules ?? MARKETING_RULES_KEYWORDS)]

  const values = useWatch({ control, name: rulesToCheck })

  const isEachSelected = values.every(Boolean)

  useEffect(() => {
    setValue('all', isEachSelected)
  }, [isEachSelected])

  const handleSelectAll = () => {
    rulesToCheck.forEach(additionalRule => {
      setValue(additionalRule, !isEachSelected)
    })
  }

  if (
    rulesToCheck.length === MARKETING_RULES_KEYWORDS.length &&
    rulesToCheck.every(rule => MARKETING_RULES_KEYWORDS.includes(rule))
  )
    return null

  return (
    <div>
      <strong className="text-gold text-center text-sm-start d-block">{title}</strong>
      <div className={className}>
        <Checkbox name="all" label={selectAllLabel} className="font-700" onClick={handleSelectAll} />
        <div className="ms-4 pt-2 font-xs d-flex flex-column gap-2">
          {children}
          {withMarketingRules && <MarketingAgreementSection fullWidth={fullWidth} />}
        </div>
      </div>
    </div>
  )
}
