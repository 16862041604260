import * as React from 'react'

interface Props {
  children: React.ReactNode
}

const snowContent = ['&#10052', '&#10053', '&#10054']
const random = num => Math.floor(Math.random() * num)

export const VipWinterHeaderSnowfallContainer = ({ children }: Props): JSX.Element => {
  const snowContainer = React.useRef<HTMLDivElement | null>(null)

  const getRandomStyles = () => {
    const top = random(300)
    const left = random(95)
    const dur = random(10) + 20
    const size = random(25) + 10

    return `
    top: -${top}%;
    left: ${left}%;
    font-size: ${size}px;
    animation-duration: ${dur}s;
  `
  }

  const createSnow = num => {
    for (let i = num; i > 0; i--) {
      const snow = document.createElement('div')
      snow.className = 'snow-flake'
      snow.style.cssText = getRandomStyles()
      snow.innerHTML = snowContent[random(3)]
      snowContainer.current?.append(snow)
    }
  }

  const removeSnow = () => {
    if (!snowContainer.current) return

    snowContainer.current.style.opacity = '0'

    setTimeout(() => {
      snowContainer.current?.remove()
    }, 500)
  }

  React.useEffect(() => {
    createSnow(300)

    return removeSnow
  }, [])

  return (
    <div className="position-relative" ref={snowContainer}>
      {children}
    </div>
  )
}
