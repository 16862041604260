import * as React from 'react'
import { AppHeaderContent } from '@components/app-header-content'
import { PackageWholesaleDetails } from '@modules/package-wholesale/models'
import { Spinner } from '@components/spinner'
import { useRemainingTime } from '@hooks/use-remaining-time'
import { DateFormats, formatDate, parseISODate } from '@helpers/date-formatter'
import { differenceInDays } from 'date-fns'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  packageWholesaleDetails: PackageWholesaleDetails
}

export const PackageWholesaleSubscriptionHeaderVariantsSectionContent = ({
  packageWholesaleDetails,
}: Props): JSX.Element => {
  const { remainingTime, isLoading: isRemainingTimeCalculating } = useRemainingTime(
    packageWholesaleDetails.expire_after,
  )

  const isOnlyWeekLeft = differenceInDays(parseISODate(packageWholesaleDetails.expire_after), new Date()) < 7

  return (
    <AppHeaderContent
      title={
        <>
          Oferta specjalna tylko dla {packageWholesaleDetails.display_name || packageWholesaleDetails.invoice_company}
        </>
      }
      subtitle={
        <div className="mt-5">
          {isOnlyWeekLeft ? (
            isRemainingTimeCalculating ? (
              <Spinner className="text-white spinner-border-sm" />
            ) : (
              <>
                <strong className="d-block font-xl">Oferta wygasa za:</strong>

                <IconWithText
                  text={
                    isRemainingTimeCalculating ? <Spinner className="text-white spinner-border-sm" /> : remainingTime
                  }
                  iconClassName="uil-clock-five text-danger me-2"
                  className="fw-bold text-white mt-1"
                />
              </>
            )
          ) : (
            <div className="text-white">
              <strong className="d-block font-xl">Oferta ważna tylko do:</strong>
              <div className="d-flex align-items-end mt-1 justify-content-center justify-content-sm-start">
                <span className="font-xxl font-500 me-1 lh-1">
                  {formatDate(packageWholesaleDetails.expire_after, DateFormats.DAY)}
                </span>
                <span className="font-xl font-500 me-1 lh-initial">
                  {formatDate(packageWholesaleDetails.expire_after, DateFormats.FULL_MONTH_FULL_YEAR)}
                </span>
              </div>
            </div>
          )}
        </div>
      }
    />
  )
}
