import * as React from 'react'
import { Form, FormCheckProps } from 'react-bootstrap'
import * as clsx from 'clsx'
import { Controller, useFormContext } from 'react-hook-form'
import { FormError } from '@components/form-controls/form-error'

interface Props extends FormCheckProps {
  name: string
  indeterminate?: boolean
}

export const Checkbox = ({ name, indeterminate, ...props }: Props): React.ReactElement => {
  const { control } = useFormContext()

  const inputRef = React.useRef<HTMLInputElement>()

  React.useEffect(() => {
    if (inputRef.current && indeterminate !== undefined) {
      inputRef.current.indeterminate = indeterminate
    }
  }, [indeterminate])

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <>
          <Form.Check
            {...field}
            {...props}
            id={name}
            type="checkbox"
            checked={field.value ?? false}
            className={clsx('checkbox cursor-pointer', props.className)}
            ref={(input: HTMLInputElement) => {
              field.ref(input)
              inputRef.current = input
            }}
          />
          <FormError inputName={name} />
        </>
      )}
    />
  )
}
