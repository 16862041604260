import { asDecimal } from '@helpers/utils'
import Decimal from 'decimal.js'

interface Price {
  sell_price: string
  declared_sell_price: string
  promotion_multiplier: string
}

export const getGastroProductPrice = <T extends Price>(details: T): { sellPrice: Decimal; bonusPrice: string } => {
  const sellPrice = asDecimal(details.sell_price)

  const bonusPrice = asDecimal(sellPrice.gt(asDecimal(0)) ? details.sell_price : details.declared_sell_price)
    .mul(details.promotion_multiplier)
    .toString()

  return { sellPrice, bonusPrice }
}
