import { CardContentBaseDataRow } from '@components/card-content-base-data-row'
import { DateFormats, formatDate, getDifferenceInDays } from '@helpers/date-formatter'
import * as React from 'react'
import { ReservationDetails } from '@modules/reservation/models'
import { AccommodationType, Resort } from '@models/app-data'
import { useDevicesSize } from '@hooks/use-devices-size'
import { ReservationDetailsInformationMobileRow } from '@modules/reservation/details/reservation-details-information-mobile-row'
import { declination } from '@helpers/declination'
import { ProductStatusLabel } from '@components/product-status-label'

interface Props {
  reservationDetails: ReservationDetails
  reservationResort: Resort | undefined
  reservationAccommodationType: AccommodationType | undefined
  onScrollToBill: () => void
}

export const ReservationDetailsInformation = ({
  reservationDetails,
  reservationResort,
  reservationAccommodationType,
  onScrollToBill,
}: Props): JSX.Element => {
  const { isMobile } = useDevicesSize()

  const daysDiff = getDifferenceInDays(reservationDetails.date_from, reservationDetails.date_to)

  return (
    <>
      <strong className="text-gold text-center text-sm-start d-block mt-3 mt-sm-0">Informacje o rezerwacji</strong>
      {isMobile ? (
        <div className="mt-3">
          <ReservationDetailsInformationMobileRow
            icon="uil-wallet"
            title="Sposób płatności:"
            value={<ProductStatusLabel isPaid={reservationDetails.is_paid} onLabelClick={onScrollToBill} />}
          />
          <ReservationDetailsInformationMobileRow
            icon="uil-bookmark"
            title="Numer rezerwacji:"
            value={reservationDetails.reservation_number}
          />
          <ReservationDetailsInformationMobileRow
            icon="uil-arrow-down-right"
            title="Przyjazd:"
            value={formatDate(reservationDetails.date_from, DateFormats.DATE_WITH_DAY)}
          />
          <ReservationDetailsInformationMobileRow
            icon="uil-arrow-up-right"
            title="Wyjazd:"
            value={`${formatDate(reservationDetails.date_to, DateFormats.DATE_WITH_DAY)} (${daysDiff}
            ${declination.hotelDay(daysDiff)})`}
          />
          <ReservationDetailsInformationMobileRow
            icon="uil-location-point"
            title="Lokalizacja:"
            value={reservationResort?.name || ''}
          />
          <ReservationDetailsInformationMobileRow
            icon="uil-pathfinder-unite"
            title="Rodzaj lokalu:"
            value={`${reservationDetails.accommodation_type_name} ${reservationAccommodationType?.size}m²`}
          />
          {reservationDetails.selected_apartment_name && (
            <ReservationDetailsInformationMobileRow
              icon="uil-home-alt"
              title="Numer lokalu:"
              value={reservationDetails.selected_apartment_name}
            />
          )}
        </div>
      ) : (
        <div className="text-darker-gray mt-3 d-flex flex-column gap-1 font-md">
          <CardContentBaseDataRow
            name="Status płatności:"
            value={<ProductStatusLabel isPaid={reservationDetails.is_paid} onLabelClick={onScrollToBill} />}
          />
          <CardContentBaseDataRow name="Numer rezerwacji:" value={reservationDetails.reservation_number} />
          <CardContentBaseDataRow
            name="Przyjazd:"
            value={formatDate(reservationDetails.date_from, DateFormats.DATE_WITH_DAY)}
          />
          <CardContentBaseDataRow
            name="Wyjazd:"
            value={`${formatDate(reservationDetails.date_to, DateFormats.DATE_WITH_DAY)} (${daysDiff}
            ${declination.hotelDay(daysDiff)})`}
          />
          <CardContentBaseDataRow name="Lokalizacja:" value={reservationResort?.name || ''} />
          <CardContentBaseDataRow
            name="Rodzaj lokalu:"
            value={`${reservationDetails.accommodation_type_name} ${reservationAccommodationType?.size}m²`}
          />
          {reservationDetails.selected_apartment_name && (
            <CardContentBaseDataRow name="Numer lokalu:" value={reservationDetails.selected_apartment_name} />
          )}
        </div>
      )}
    </>
  )
}
