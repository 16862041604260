import * as React from 'react'
import * as clsx from 'clsx'
import { Carousel } from 'react-bootstrap'

interface Video {
  src: string
  onLoadEnd: () => void
}

interface Props {
  images?: string[]
  video?: Video
  imageDescription: string
  children: React.ReactNode
  imageClassName?: string
}

export const AppHeaderSection = ({
  images = [],
  imageDescription,
  imageClassName,
  children,
  video,
}: Props): JSX.Element => (
  <div className="app-header-section">
    {video && (
      <video
        onLoadedData={video.onLoadEnd}
        onError={video.onLoadEnd}
        src={video.src}
        autoPlay
        muted
        loop
        className="app-header-section__background is-video"
      />
    )}

    {!video && images?.length === 1 && (
      <img className="app-header-section__background" src={images[0]} alt={imageDescription} />
    )}

    {!video && !!images?.length && (
      <Carousel className="app-header-section__background" indicators={false}>
        {images.map((image, index) => (
          <Carousel.Item key={index} interval={5000}>
            <img
              className={clsx('app-header-section__background__img', imageClassName)}
              src={image}
              alt={imageDescription}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    )}

    <div className="app-header-section__icons py-3">
      <div className="d-flex container align-items-center">
        <img
          src={require('@assets/images/logo_holiday_white@3x.svg')}
          alt="holidaypark logo"
          className="app-header-section__icon"
        />
        {/*TODO make it work, translations have not been provided*/}
        {/*<LanguageSelection className="ms-auto" />*/}
      </div>
    </div>
    {children}
  </div>
)
