export const declination = {
  person: (num: number): string => (num >= 5 ? 'osób' : num >= 2 ? 'osoby' : 'osoba'),
  stayDays: (num: number): string => (num >= 5 ? 'pobytów' : num >= 2 ? 'pobyty' : 'pobyt'),
  hotelDay: (num: number): string => (num >= 5 ? 'dób' : num >= 2 ? 'doby' : 'doba'),
}

export const yearsVariation = (years: number): string => (years === 1 ? 'roku' : 'lat')

export const yearsUsageVariation = (years: number): string => {
  if (years === 1) {
    return 'rok'
  }
  if (years > 1 && years < 5) {
    return 'lata'
  }
  return 'lat'
}
