import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { formatPrice } from '@helpers/price-helper'
import { useRemainingTime } from '@hooks/use-remaining-time'
import { formatDate } from '@helpers/date-formatter'
import { add } from 'date-fns'
import { Spinner } from '@components/spinner'
import { ActionRow } from '@components/action-row'

interface Props {
  className?: string
  amount: string | number
  isLoading: boolean
  paymentDate?: string | Date
  isPaymentDisabled?: boolean
  buttonContent?: React.ReactElement | undefined
}

export const PaymentActionRow = React.forwardRef(
  (
    { isLoading, className, amount, paymentDate, isPaymentDisabled, buttonContent }: Props,
    ref: React.Ref<HTMLDivElement>,
  ): JSX.Element => (
    <ActionRow
      className={clsx('justify-content-between', className)}
      isActionDisabled={isPaymentDisabled}
      isLoading={isLoading}
      ref={ref}
      buttonContent={buttonContent}
      rightSide={<PaymentDate paymentDate={paymentDate} />}
    >
      <div className="text-white d-flex flex-column align-items-lg-start align-items-center">
        <span className="font-500">Łącznie do zapłaty:</span>
        <strong className="d-block font-xxxl lh-initial">{formatPrice(amount)}</strong>
      </div>
    </ActionRow>
  ),
)

const PaymentDate = ({ paymentDate }) => {
  const { remainingTime, isLoading: isRemainingTimeCalculating } = useRemainingTime(
    paymentDate || formatDate(add(new Date(), { days: 5 })),
  )

  if (!paymentDate) return null

  return (
    <div className="font-md d-flex gap-2 d-flex align-items-center text-white text-sm-start justify-content-center">
      <strong>Termin płatności:</strong>
      <IconWithText
        text={isRemainingTimeCalculating ? <Spinner className="text-white spinner-border-sm" /> : remainingTime}
        iconClassName="uil-clock-five me-1"
        className="fw-bold"
      />
    </div>
  )
}
