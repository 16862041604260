import * as React from 'react'
import { GlobalErrorModal } from '@components/modals/global-error-modal'
import { ModalProps } from 'react-bootstrap'
import { RodoAgreementQrCodeModal } from '@modules/rodo-agreements/modals/qr-code-modal'
import { RodoAgreementContentModal } from '@modules/rodo-agreements/modals/agreement-modal'
import { RodoAgreementDataExistModal } from '@modules/rodo-agreements/modals/agreement-data-exist-modal'

export type ModalTypes =
  | 'GlobalErrorModal'
  | 'RodoAgreementQrCodeModal'
  | 'RodoAgreementContentModal'
  | 'RodoAgreementDataExistModal'

export type ModalParams = ModalProps

type ModalsMapType = {
  [key in ModalTypes]: [React.FC<ModalProps & BaseModalProps>, ModalParams]
}

export const ModalsMap: ModalsMapType = {
  GlobalErrorModal: [GlobalErrorModal, { centered: true }],
  RodoAgreementQrCodeModal: [RodoAgreementQrCodeModal, { centered: true, className: 'rodo-agreements__qr-modal' }],
  RodoAgreementContentModal: [RodoAgreementContentModal, { centered: true, className: 'rodo-agreements__qr-modal' }],
  RodoAgreementDataExistModal: [
    RodoAgreementDataExistModal,
    { centered: true, className: 'rodo-agreements__qr-modal' },
  ],
}

export interface ModalConfig {
  persist?: boolean // nie usuwa modala po wyjściu z widoku
  onClose?: () => void
}

export interface BaseModalProps {
  toggleIsVisible: () => void
}
