import {
  GastroGenericProductDetails,
  GastroProduct,
  GastroProductData,
  GastroProductKind,
} from '@modules/gastro-generic/models'
import { getGastroCouponData } from '@modules/gastro-generic/kinds-data/gastro-coupon-kind-data'
import { getGastroCardBoostData } from '@modules/gastro-generic/kinds-data/gastro-coupon-boost-kind-data'
import { getGastroCard2024Data } from '@modules/gastro-generic/kinds-data/gastro-card-2024-kind-data'
import { getGastroCard2025Data } from '@modules/gastro-generic/kinds-data/gastro-card-2025-kind-data'

export const getProductData = (
  productDetails: GastroGenericProductDetails | undefined,
  gastroProduct: GastroProduct,
): GastroProductData => {
  const emptyProductData = {
    alertText: '',
    labelImageSrc: '',
    productName: '',
    productHeader: '',
    benefitsPoints: [],
    benefitsList: [],
  }
  if (!productDetails) {
    return emptyProductData
  }

  const data: Record<GastroProductKind, GastroProductData> = {
    gastro_coupon: getGastroCouponData(productDetails, gastroProduct),
    gastro_card_boost: getGastroCardBoostData(productDetails, gastroProduct),
    normal: emptyProductData,
    gastro_card_2024: getGastroCard2024Data(productDetails, gastroProduct),
    gastro_card_2025: getGastroCard2025Data(productDetails, gastroProduct),
    gastro_card_2024_wholesale: getGastroCard2024Data(productDetails, gastroProduct),
    gastro_card_2025_wholesale: getGastroCard2025Data(productDetails, gastroProduct),
  }

  return data[gastroProduct.kind]
}
