import * as React from 'react'
import { FormControlProps } from 'react-bootstrap'
import { FormLabelProps } from 'react-bootstrap/FormLabel'
import { FormGroupProps } from 'react-bootstrap/FormGroup'
import { FormControlWithLabel } from '@components/form-controls/form-control-with-label'
import { CustomFormControl } from '@components/form-controls/form-control'
import { MaskedFormControl, MaskedFormControlProps } from './masked-form-control'

export interface CustomFormGroupProps {
  label?: React.ReactNode
  inputName: string
  formControlProps?: FormControlProps
  formLabelProps?: FormLabelProps
  formGroupProps?: FormGroupProps
  isLoading?: boolean
  isSucceed?: boolean
  isFailed?: boolean
  inputSuffix?: React.ReactNode
  maskedFormControlProps?: Omit<MaskedFormControlProps, 'name'>
}

export const CustomFormGroup = ({
  label,
  inputName,
  formControlProps,
  formLabelProps,
  formGroupProps,
  isLoading,
  isSucceed,
  isFailed,
  inputSuffix,
  maskedFormControlProps,
}: CustomFormGroupProps): React.ReactElement => {
  return (
    <FormControlWithLabel
      inputName={inputName}
      label={label}
      formLabelProps={formLabelProps}
      formGroupProps={formGroupProps}
      isLoading={isLoading}
      isSucceed={isSucceed}
      isFailed={isFailed}
      inputSuffix={inputSuffix}
    >
      {maskedFormControlProps ? (
        <MaskedFormControl name={inputName} className={formControlProps?.className} {...maskedFormControlProps} />
      ) : (
        <CustomFormControl inputName={inputName} formControlProps={formControlProps} />
      )}
    </FormControlWithLabel>
  )
}
