import * as React from 'react'
import { formatDate, parseISODate } from '@helpers/date-formatter'
import { IconWithText } from '@components/icon-with-text'
import { formatPriceShort } from '@helpers/price-helper'
import { differenceInDays } from 'date-fns'
import classNames from 'classnames'
import { declination } from '@helpers/declination'
import { useSelector } from 'react-redux'
import { selectAppData } from '@store/selectors/app-data-selector'
import { BookingOffer } from '@models/booking-offers'

interface Props {
  offer: BookingOffer
}

export const BookingOffersCard: React.FC<Props> = ({ offer }) => {
  const { resorts, accommodation_types } = useSelector(selectAppData)

  const dateFrom = React.useMemo(() => parseISODate(offer.date_from) || 0, [offer.date_from])
  const dateTo = React.useMemo(() => parseISODate(offer.date_to) || 0, [offer.date_to])

  const numberOfNights = differenceInDays(dateTo, dateFrom)

  const resort = resorts.find(resort => resort.id === offer.resort_id)
  const accommodationType = accommodation_types.find(
    accommodationType => accommodationType.id === offer.accommodation_type_id,
  )

  const resortImageSrc = resort?.reservation_images.length ? resort.reservation_images[0].image.xs : ''

  const season = 'summer'

  return (
    <div className="col-md-4 col-12">
      <div className={classNames('booking-offers__card', { 'is-inactive pe-none': !offer.is_available })}>
        <img
          src={resortImageSrc}
          alt={`${resort?.name} Holiday Park & Resort`}
          className="booking-offers__card__image rounded-top"
        />
        <div className="booking-offers__card__season-signature__wrapper">
          <div className={classNames('booking-offers__card__season-signature', { 'is-summer': season === 'summer' })} />
        </div>

        <div className="p-3">
          <strong className="text-primary">{resort?.name}</strong>
          <div className="d-flex align-items-center">
            <strong>{formatDate(offer.date_from, 'dd.MM')}</strong>
            <i className="uil-arrow-right text-primary font-xxl mx-2" />
            <strong className="pe-1">{formatDate(offer.date_to, 'dd.MM.yyyy')}</strong>
            <span>
              ({numberOfNights} {declination.hotelDay(numberOfNights)})
            </span>
          </div>
        </div>
        <div className="d-flex ps-3 justify-content-between">
          <div className="pb-3">
            <IconWithText
              iconClassName="uil-pathfinder-unite font-xl me-2 text-primary"
              text={`${accommodationType?.name} ${accommodationType?.size}m²`}
            />
            <IconWithText
              iconClassName="uil-usd-circle font-xl me-2 text-primary"
              text={formatPriceShort(offer.price_brutto)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
