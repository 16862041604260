import { ThunkResult } from '@models/models'
import { AppData } from '@models/app-data'
import { SetAppDataAction } from '@store/action-types/app-data-action-types'
import { setAppData } from '@store/actions/app-data-actions'
import { commonObjectGet } from '@requests/basic-requests'

export function fetchAppData(): ThunkResult<Promise<void>, SetAppDataAction> {
  return async dispatch => {
    dispatch(setAppData(await commonObjectGet<AppData>('/api/promotions/app-data/')))
  }
}
