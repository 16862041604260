import { combineReducers } from 'redux'
import appDataState from '@store/reducers/app-data-reducers'
import webSocketState from '@store/reducers/websocket-reducers'
import modalState from '@store/slices/modal-slice'

const rootReducer = combineReducers({
  appDataState,
  webSocketState,
  modalState,
})

export default rootReducer
