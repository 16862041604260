import * as React from 'react'
import * as clsx from 'clsx'
import { RefObject } from 'react'
import { useClickOutside } from '@hooks/use-click-outside'

interface Props {
  children: JSX.Element | JSX.Element[]
  content: JSX.Element
  isShowed?: boolean
  onClose?: () => void
  showOnHover?: boolean
  tooltipClassName?: string
}

export const CustomTooltip = React.forwardRef(
  ({ children, isShowed, onClose, content, showOnHover, tooltipClassName }: Props, ref: RefObject<HTMLDivElement>) => {
    const handleClose = () => {
      if (isShowed) onClose?.()
    }

    useClickOutside(ref, handleClose)

    return (
      <div className={clsx('tooltip', { 'tooltip--hovered': showOnHover }, tooltipClassName)} ref={ref}>
        {children}
        <span className={clsx('tooltip__content', { 'tooltip__content--visible': isShowed })}>{content}</span>
      </div>
    )
  },
)
