import { ActionRow } from '@components/action-row'
import * as React from 'react'

interface Props {
  isLoading: boolean
}

export const GastroGenericRulesAcceptationRow = ({ isLoading }: Props): JSX.Element => (
  <ActionRow className="mt-4 justify-content-end" isLoading={isLoading} buttonContent={<ButtonContent />} />
)

const ButtonContent = () => (
  <>
    <span className="font-500 d-block font-sm lh-initial">Akceptacja regulaminów</span>
    <strong className="font-xl">Akceptuję</strong>
  </>
)
