import { AppDataActionsTypes, AppDataActionTypesKeys } from '@store/action-types/app-data-action-types'
import { AppData } from '@models/app-data'

export interface AppDataState {
  appData: AppData
}

const initialState: AppDataState = {
  appData: {
    resorts: [],
    countries: [],
    company_info: {
      call_center_email: '',
      call_center_phone_number: {
        pl: '',
        en: '',
        de: '',
      },
      call_center_working_hours: {},
    },
    accommodation_types: [],
    payment_methods: [],
    urls: {
      shop: {
        booking_offers: '',
      },
      generic_voucher: {
        bocian_create: '',
        bocian_rules: '',
      },
      other: {
        main_site: '',
      },
      rodo_acquiring_point: { details: '', confirm: '' },
      products: {
        client_options_purchase: '',
      },
      clients: { update_rodo_data: '' },
      codes: {
        details: '',
      },
      reservation: {
        details: '',
      },
      subscription: {
        details: '',
      },
      package_vip: {
        details: '',
      },
      subscription_contract: {
        details: '',
      },
      gastro_card: {
        details: '',
      },
      package_wholesale: {
        details: '',
        subscription: '',
      },
      sale: {
        nip_search: '',
      },
    },
    rules: [],
    gastro_products: {
      gastro_card_boost: {
        product_name: '',
        minimal_value: '',
        assign_type: 'client',
        kind: 'gastro_card_boost',
        multiplier: 1.4,
        date_from: '2023-01-01',
        date_to: '2023-12-31',
      },
      gastro_coupon: {
        product_name: '',
        minimal_value: '',
        assign_type: 'booking',
        kind: 'gastro_coupon',
        multiplier: 1.1,
        date_from: '2023-01-01',
        date_to: '2023-12-31',
      },
      normal: {
        product_name: '',
        minimal_value: '',
        assign_type: 'booking',
        kind: 'normal',
        multiplier: 2,
        date_from: '2024-01-01',
        date_to: '2025-12-31',
      },
      gastro_card_2024: {
        product_name: '',
        minimal_value: '',
        assign_type: 'booking',
        kind: 'gastro_card_2024',
        multiplier: 1.9,
        date_from: '2024-01-01',
        date_to: '2024-12-31',
      },
      gastro_card_2024_wholesale: {
        product_name: '',
        minimal_value: '',
        assign_type: 'booking',
        kind: 'gastro_card_2024_wholesale',
        multiplier: 1.9,
        date_from: '2024-01-01',
        date_to: '2024-12-31',
      },
      gastro_card_2025: {
        product_name: '',
        minimal_value: '',
        assign_type: 'booking',
        kind: 'gastro_card_2025',
        multiplier: 1.9,
        date_from: '2025-01-01',
        date_to: '2025-12-31',
      },
      gastro_card_2025_wholesale: {
        product_name: '',
        minimal_value: '',
        assign_type: 'booking',
        kind: 'gastro_card_2025_wholesale',
        multiplier: 1.9,
        date_from: '2025-01-01',
        date_to: '2025-12-31',
      },
    },
  },
}

const appDataState = (state: AppDataState = initialState, action: AppDataActionsTypes): AppDataState => {
  switch (action.type) {
    case AppDataActionTypesKeys.SET_APP_DATA:
      return { ...state, appData: action.payload }
    default:
      return state
  }
}

export default appDataState
