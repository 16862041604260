import * as React from 'react'

interface Props {
  onScrollToResortSection: () => void
}

export const BocianVoucherDescription = ({ onScrollToResortSection }: Props): JSX.Element => (
  <div className="col-lg-6 col-12 text-secondary bocian-text-primary text-center text-lg-start">
    <h5 className="text-primary">Oprócz siana - plaża i morze od Bociana!</h5>
    <h3 className="mb-4 fw-bold">Dziękujemy za wybranie Bocian Finanse!</h3>
    <p>
      Zarejestruj się, aby wziąć udział w Programie i móc otrzymać voucher na 2-dobowy pobyt w jednym z nadmorskich
      ośrodków Holiday Park & Resort.
    </p>
    <p>
      Szczegóły znajdziesz w{' '}
      <a href="https://" target="_blank" className="fw-bold text-decoration-none bocian-text-primary text-nowrap">
        Regulaminie Oprócz siana - plaża i morze od Bociana!
      </a>
    </p>
    <p className="font-xs">
      Organizatorem jest Everest Finanse Spółka Akcyjna z siedzibą w Poznaniu, ul. Stary Rynek 88; 61-772 Poznań,
      zarejestrowana w rejestrze przedsiębiorców prowadzonym przez Sąd Rejonowy w Poznaniu – Nowe Miasto i Wilda w
      Poznaniu, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000541824, NIP 7831722320, REGON
      360730596, o kapitale zakładowym w wysokości 105.000.000,00 zł, wpłaconym w całości, z którym można się
      skontaktować pod numerem telefonu 600 100 100 (koszt połączenia jest zgodny z taryfą operatora, z którego korzysta
      Klient), adres e-mail: biuro@bocianpozyczki.pl.
    </p>
    <div className="row mt-5 flex-column flex-lg-row">
      <div className="col-lg-5 col-12">
        <h5 className="text-primary">Partner programu</h5>
        <p>
          <strong>Holiday Park & Resort to unikatowa sieć obiektów rekreacyjno-wypoczynkowych,</strong> w których
          komfort i relaks łączą się w jedność. Ośrodki powstały z myślą o rodzinach z dziećmi, zapewniając zarówno
          dorosłym, jak i najmłodszym moc niezapomnianych atrakcji.
        </p>
      </div>
      <div className="col-lg-7 col-12 text-center">
        <img
          src={require('@assets/images/bocian-voucher.webp')}
          height={180}
          alt="Dodatkowe opcje do rezerwacji w Holiday Park & Resort"
        />
      </div>
    </div>
    <div className="mt-5">
      <div className="row align-items-center justify-content-between flex-lg-row flex-column mx-0">
        <h5 className="text-primary col-lg-6 mb-lg-0 mb-3">
          6 ośrodków wypoczynkowych <br /> nad polskim morzem!
        </h5>
        <button
          className="rounded btn-darker-gray d-inline-block p-3 text-white fw-bold me-lg-2 col-12 col-lg-4 col-md-6"
          onClick={onScrollToResortSection}
        >
          Sprawdź ośrodki
        </button>
      </div>
      <p className="mt-4 font-xs">
        * Pobyty zrealizować można w dowolnym nadmorskim Resorcie Holiday Park & Resort: Pobierowo, Rowy, Ustronie
        Morskie, Niechorze, Mielno (Unieście), Kołobrzeg (Grzybowo). Wyboru Resortu dokonuje się w momencie zakładania
        rezerwacji. Rezerwacji dokonuje się samodzielnie poprzez stronę{' '}
        <a
          className="text-decoration-none bocian-text-primary fw-bold text-nowrap"
          target="_blank"
          href="https://rezerwuj.holidaypark.pl/"
        >
          www.rezerwuj.holidaypark.pl
        </a>{' '}
        lub telefonicznie za pomocą <strong className="text-nowrap">Biura Obsługi Klienta: 735-200-800</strong>. Przy
        zakładaniu rezerwacji należy opłacić jednorazową usługę przygotowania lokalu. Do każdej rezerwacji zostanie
        doliczona opłata eksploatacyjna i opłata klimatyczna, płatne podczas meldowania. Przy wyjeździe należy uiścić
        opłatę z tytułu zużytych mediów. Do każdego pobytu zostanie doliczona kwota zabezpieczenia na poczet kaucji
        zwrotnej w wysokości 750 zł, płatna do 12 miesięcy przed rozpoczęciem rezerwacji. Szczegóły dotyczące opłat
        znajdują się w{' '}
        <a
          className="text-decoration-none bocian-text-primary fw-bold text-nowrap"
          href="https://rezerwuj.holidaypark.pl/"
          target="_blank"
        >
          Regulaminie Rezerwacji
        </a>{' '}
        oraz{' '}
        <a
          className="text-decoration-none bocian-text-primary fw-bold"
          href="https://rezerwuj.holidaypark.pl/"
          target="_blank"
        >
          Cenniku.
        </a>
      </p>
    </div>
  </div>
)
