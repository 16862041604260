import * as React from 'react'
import * as clsx from 'clsx'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  text: string | JSX.Element
  className?: string
  iconClassName?: string
  textClassName?: string
}

export const IconWithText = ({ text, textClassName, iconClassName, className, ...props }: Props): JSX.Element => (
  <div
    className={clsx('icon-with-text d-flex align-items-center', { 'cursor-pointer': !!props.onClick }, className)}
    {...props}
  >
    <i className={clsx('icon-with-text__icon', iconClassName)} />
    <span className={clsx('icon-with-text__text', textClassName)}>{text}</span>
  </div>
)
