import * as React from 'react'
import * as clsx from 'clsx'
import { PaymentMethod } from '@models/app-data'
import { ContentLoader } from '@components/content-loader'
import { Checkbox } from '@components/controls/checkbox'
import { useFormContext, useWatch } from 'react-hook-form'
import { useActivePaymentMethods } from '@hooks/use-active-payment-methods'
import { payuPrivacyPolicy } from '@helpers/rules'
import { ExpandableText } from '@components/controls/expandable-text'
import { PaymentSelectionFormInputs } from '@components/payments/payment-selection-view'
import { FormError } from '@components/form-controls/form-error'

interface Props {
  onClick: (paymentMethod: PaymentMethod) => void
  isLoading: boolean
}

export const PaymentMethodSelection = ({ onClick, isLoading }: Props): JSX.Element => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<PaymentMethod | null>(null)

  const [imagesCount, setImagesCount] = React.useState(0)
  const [isEachImageLoaded, setIsEachImageLoaded] = React.useState(false)

  const paymentMethods = useActivePaymentMethods()

  const paymentGridRef = React.useRef<HTMLDivElement>(null)

  const { control } = useFormContext<PaymentSelectionFormInputs>()

  const paymentProviderTerms = useWatch({ control, name: 'paymentProviderTerms' })

  const handleOnClick = () => {
    if (selectedPaymentMethod) {
      onClick(selectedPaymentMethod)
    }
  }

  const handlePaymentSelection = (paymentMethod: PaymentMethod) => () => {
    setSelectedPaymentMethod(paymentMethod)
  }

  const incrementImagesCount = () => setImagesCount(state => state + 1)

  React.useEffect(() => {
    if (imagesCount === paymentMethods.length) {
      setIsEachImageLoaded(true)
    }
  }, [imagesCount])

  const providerRulesConfirmation = {
    p24: (
      <span>
        Oświadczam, że zapoznałem się z{' '}
        <a className="text-decoration-none text-primary" href="https://www.przelewy24.pl/regulamin">
          regulaminem
        </a>{' '}
        i{' '}
        <a
          className="text-decoration-none text-primary"
          href="https://www.przelewy24.pl/obowiazek-informacyjny-rodo-platnicy"
        >
          obowiązkiem informacyjnym
        </a>{' '}
        serwisu Przelewy24
      </span>
    ),
    payu: (
      <span>
        Płacąc akceptujesz{' '}
        <a
          className="text-decoration-none text-primary"
          href="https://static.payu.com/sites/terms/files/payu_terms_of_service_single_transaction_pl_pl.pdf"
        >
          Zasady płatności PayU
        </a>
        .
      </span>
    ),
  }

  return (
    <ContentLoader isLoading={!isEachImageLoaded}>
      <div className="position-fixed top-0 start-0 bg-white w-100 h-100 overflow-auto payment-selection">
        <div className="d-flex flex-column justify-content-center align-items-center py-4 container-lg">
          <img
            src={require('@assets/images/logo_holiday_color.svg')}
            alt="holidaypark logo"
            height="48"
            width="172"
            className="mb-md-5 mb-3"
          />
          <strong className="text-secondary text-center font-lg mb-2">Wybierz preferowaną formę płatności</strong>
          <div className="payment-selection__grid mb-3" ref={paymentGridRef}>
            {paymentMethods.map((paymentMethod: PaymentMethod) => (
              <button
                id={`payment-method-${paymentMethod.provider_name}-${paymentMethod.id}`}
                key={paymentMethod.id}
                title={paymentMethod.name}
                onClick={handlePaymentSelection(paymentMethod)}
                className={clsx('payment-method cursor-pointer btn-unstyled', {
                  'is-selected': selectedPaymentMethod?.id === paymentMethod.id,
                })}
              >
                <img
                  src={paymentMethod.image_url}
                  alt={paymentMethod.name}
                  className={clsx('payment-method__image', { 'is-loaded': isEachImageLoaded })}
                  onLoad={incrementImagesCount}
                  onError={incrementImagesCount}
                />
              </button>
            ))}
          </div>
          {selectedPaymentMethod && (
            <div style={{ width: paymentGridRef.current?.scrollWidth }}>
              <Checkbox
                name="paymentProviderTerms"
                label={providerRulesConfirmation[selectedPaymentMethod?.provider]}
                className="checkbox--small font-md"
              />
            </div>
          )}

          <div style={{ width: paymentGridRef.current?.scrollWidth }}>
            <FormError inputName="payment_method" className="mt-3" />
          </div>

          <button
            onClick={handleOnClick}
            disabled={isLoading || !selectedPaymentMethod || !paymentProviderTerms}
            className="btn btn-responsive btn-danger payment-selection__button"
          >
            {isLoading ? 'Proszę czekać..' : 'Kontynuuj'}
          </button>

          {paymentMethods[0].provider === 'payu' && (
            <div style={{ maxWidth: paymentGridRef.current?.scrollWidth }}>
              <ExpandableText wrapperClassName="payment-selection__expandable-privacy" text={payuPrivacyPolicy} />
            </div>
          )}
        </div>
      </div>
    </ContentLoader>
  )
}
