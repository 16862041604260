import * as React from 'react'
import * as clsx from 'clsx'
import { formatPriceShort } from '@helpers/price-helper'
import { PackageWholesaleProduct } from '@modules/package-wholesale/models'
import { SpinInput } from '@components/spin-input'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { PackageWholesaleFormInput } from '@modules/package-wholesale/details'
import { declination, yearsUsageVariation, yearsVariation } from '@helpers/declination'
import { PackageWholesaleOfferSubscriptionCardOptions } from '@modules/package-wholesale/details/subscription/card-additional-options/package-wholesale-offer-subscription-card-options'
import { useSubscriptionProductName } from '@modules/package-wholesale/details/subscription/use-subscription-product-name'
import { asDecimal } from '@helpers/utils'
import { RootState } from '@store/index'
import { formatDate } from '@helpers/date-formatter'

interface Props {
  product: PackageWholesaleProduct
  index: number
  isHidden: (product: PackageWholesaleProduct) => boolean
}

export const PackageWholesaleOfferSubscriptionCard = ({ index, product, isHidden }: Props): JSX.Element => {
  const [amount, setAmount] = React.useState(0)
  const accommodationTypes = useSelector((state: RootState) => state.appDataState.appData.accommodation_types)

  const { setValue, clearErrors, watch } = useFormContext<PackageWholesaleFormInput>()

  const { getSubscriptionProductName } = useSubscriptionProductName()

  const isEnabled = watch(`products.${index}.active`)

  const selectFreeOptions = () => {
    const includedOptions = product.options.reduce(
      (options, option) => (asDecimal(option.price_brutto).eq(0) ? { ...options, [option.kind]: true } : options),
      {},
    )

    setValue(`products.${index}.options`, includedOptions)
  }

  const handleChange = value => {
    setAmount(value)

    if (!value) {
      setValue(`products.${index}.active`, false)
      setValue(`products.${index}.options`, { zoo_borysew: false })
    } else {
      setValue(`products.${index}.active`, true)
    }

    setValue(`products.${index}.amount`, value)
    clearErrors(`products.${index}.amount`)
  }

  const accommodationType = React.useMemo(
    () => accommodationTypes.find(row => row.id === product.accommodation_type_id),
    [product.accommodation_type_id, accommodationTypes],
  )

  const handleActive = () => {
    if (!isEnabled) {
      handleChange(1)
      selectFreeOptions()
    }
  }

  const productName = getSubscriptionProductName(product)

  const hasOptions = !!product.options.length

  return (
    <div
      className={clsx('package-wholesale__cards__card', {
        'is-disabled': !isEnabled,
        'd-none': isHidden(product),
      })}
      data-testid={`product-card-${product.id}`}
      onClick={handleActive}
      role="presentation"
    >
      <div className={clsx({ 'pe-none': !isEnabled })}>
        <div className="package-wholesale__cards__card__image" style={{ backgroundImage: `url(${product.image.md})` }}>
          <span className="package-wholesale__cards__card__costs">
            {formatPriceShort(product?.price_brutto ?? 0)}
            <div className="d-flex align-items-end flex-column">
              <span className="package-wholesale__cards__card__standard-price__title">Cena standardowa:</span>
              <span className="package-wholesale__cards__card__standard-price__price">
                {formatPriceShort(product.standard_price_brutto)}
              </span>
            </div>
          </span>
        </div>
        <div className={clsx('package-wholesale__cards__card__content border-bottom', { 'rounded-0': hasOptions })}>
          <p className="font-xl lh-initial mb-1">
            {productName} <br /> {product.discount && <span>z rabatem do {product.discount}% </span>}
          </p>

          <div className="package-wholesale__cards__card__description">
            Cena za cały {accommodationType?.display_max_guests}-osobowy {accommodationType?.name.toLocaleLowerCase()}{' '}
            na{' '}
            <span className="text-nowrap">
              {product.defaults_days} {declination.hotelDay(product.defaults_days)} (
              {accommodationType?.display_max_adult_guests} os. dorosł.)
            </span>
            <small className="d-block mt-1">
              {product.stay_date_from ? (
                `Voucher do wykorzystania na pobyt w roku ${formatDate(product.stay_date_from, 'yyyy')}. Ważny ${
                  product.expire_after_years
                } ${yearsUsageVariation(product.expire_after_years)} od daty zakupu.`
              ) : (
                <span>
                  Voucher do wykorzystania w ciągu {product.expire_after_years}{' '}
                  {yearsVariation(product.expire_after_years)} <span className="text-nowrap">od daty zakupu.</span>
                </span>
              )}
            </small>
          </div>

          <div className="d-flex align-items-center justify-content-end gap-3 mt-2">
            <span className="d-block font-sm fw-normal">Ilość:</span>
            <SpinInput value={amount} min={0} onIncrement={handleChange} onDecrement={handleChange} readOnly />
          </div>
        </div>
        {hasOptions && (
          <PackageWholesaleOfferSubscriptionCardOptions index={index} isEnabled={isEnabled} options={product.options} />
        )}
      </div>
    </div>
  )
}
