import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { PaymentActionRow } from '@components/payments/payment-action-row'
import { AppFooter } from '@components/app-footer'
import { VipSideCard } from '@modules/vip/details/vip-side-card'
import { PackageVipDetails } from '@modules/vip/models'
import { useFormRequest } from '@hooks/use-form-request'
import { commonObjectPost } from '@requests/basic-requests'
import { useSelector } from 'react-redux'
import { selectAppDataUrls } from '@store/selectors/app-data-selector'
import { GastroCardDetails } from '@modules/gastro-card/models'
import { usePaymentSelection } from '@hooks/use-payment-selection'
import { ProductKinds } from '@components/payments/payment-selection-view'
import { Agreements } from '@modules/package-wholesale/details/package-wholesale-agreements'
import { useScrollIntoView } from '@hooks/use-scroll-into-view'
import { useVipKind } from '@modules/vip/details/kind/use-vip-kind'
import { useProductDetails } from '@hooks/use-product-details'
import { DynamicAgreementsSection } from '@components/agreement/dynamic-agreements-section'

interface FormInputs extends Agreements {
  vip_rules: boolean
}

export const VipDetailsView = (): JSX.Element => {
  const packageVipDetailsUrl = useSelector(selectAppDataUrls).package_vip.details

  const { PageWrapper, productDetails: packageVipDetails } = useProductDetails<PackageVipDetails>(packageVipDetailsUrl)

  const improvementListRef = React.useRef<HTMLDivElement>(null)
  const paymentSectionRef = React.useRef<HTMLDivElement>(null)

  const { scrollTo } = useScrollIntoView()

  const methods = useForm<FormInputs>({ reValidateMode: 'onChange' })
  const { showPaymentSelectionView } = usePaymentSelection()

  const { isLoading: isSaving, action: onSubmit } = useFormRequest(async payload => {
    if (packageVipDetails) {
      if (packageVipDetails.has_rules_to_accept) {
        await commonObjectPost<GastroCardDetails>(packageVipDetails.urls.rules, payload)
      }

      showPaymentSelectionView(packageVipDetails.token, ProductKinds.PACKAGE_VIP)
    }
  }, methods.setError)

  const handleScroll = (destination: 'improvements' | 'payment') => {
    if (destination === 'improvements') return scrollTo(improvementListRef)

    return scrollTo(paymentSectionRef)
  }

  const { headerSectionContent, content } = useVipKind(packageVipDetails, handleScroll, improvementListRef)

  return (
    <PageWrapper>
      {headerSectionContent}
      <div className="container app-header-section__after-content">
        <div className="row gx-xl-5">
          <div className="card col-lg-9 col-12 py-4 card-shadow border-0 overflow-hidden">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                {content}
                {packageVipDetails && !packageVipDetails.is_paid && (
                  <>
                    <hr className="mx-n4" />
                    {packageVipDetails && packageVipDetails.has_rules_to_accept && (
                      <DynamicAgreementsSection
                        productRules={[...packageVipDetails.optional_rules, ...packageVipDetails.required_rules]}
                      />
                    )}
                    <PaymentActionRow
                      ref={paymentSectionRef}
                      isLoading={isSaving}
                      amount={packageVipDetails?.price_brutto || '0'}
                      className="mt-4"
                    />
                  </>
                )}
              </form>
            </FormProvider>
          </div>
          <VipSideCard />
        </div>
      </div>
      <AppFooter />
    </PageWrapper>
  )
}
