import { CardContentBase } from '@components/card-content-base'
import * as React from 'react'
import { PackageVipDetails } from '@modules/vip/models'

interface Props {
  packageVipDetails: PackageVipDetails
  onScrollToPayment: () => void
  children: React.ReactNode
  improvementsTitle: string
  titleClassName?: string
  fineText: string
}

export const VipContentBase = React.forwardRef(
  (
    { packageVipDetails, onScrollToPayment, titleClassName, children, improvementsTitle, fineText }: Props,
    ref: React.Ref<HTMLDivElement>,
  ): JSX.Element => (
    <div>
      <CardContentBase
        created={packageVipDetails.created}
        seller={packageVipDetails.seller}
        client={packageVipDetails.client}
        isProductPaid={packageVipDetails.is_paid}
        title="Potwierdzenie zakupu pakietu VIP"
        titleClassName={titleClassName}
        onScrollToPayment={onScrollToPayment}
        productInvoice={packageVipDetails}
      />
      <hr className="mx-n5" />
      <div ref={ref}>
        <strong className="text-secondary font-xxl">{improvementsTitle}</strong>
        <strong className="text-darker-gray d-block mt-1">Kupując pakiet VIP otrzymasz:</strong>
        <div className="mt-3 vip-details__wrapper">{children}</div>
        <span className="text-muted font-sm mt-3 d-block">
          <span className="text-gold">*</span>
          {fineText}
        </span>
      </div>
    </div>
  ),
)
