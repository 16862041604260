import * as React from 'react'
import { useFormRequest } from '@hooks/use-form-request'
import { AppFooter } from '@components/app-footer'
import { AppHeaderSection } from '@components/app-header-section'
import { ResortsGallery } from '@components/resorts-gallery'
import { PaymentActionRow } from '@components/payments/payment-action-row'
import { FormProvider, useForm } from 'react-hook-form'
import { Agreements } from '@modules/package-wholesale/details/package-wholesale-agreements'
import { SubscriptionContractDetails } from '@modules/subscription-contract/models'
import { SubscriptionContractHeaderSectionContent } from '@modules/subscription-contract/subscription-contract-header-section-content'
import { SubscriptionContractDetailsContent } from '@modules/subscription-contract/subscription-contract-details-content'
import { SubscriptionContractDetailsCardAgreements } from '@modules/subscription-contract/subscription-contract-details-card-agreements'
import { commonObjectPost } from '@requests/basic-requests'
import { useSelector } from 'react-redux'
import { selectAppDataUrls } from '@store/selectors/app-data-selector'
import { ResortImages } from '@modules/package-wholesale/details'
import { useProductDetails } from '@hooks/use-product-details'
import { useGtmEcommerceBeginCheckoutGtmEvent } from '@hooks/gtm/ecommerce/use-begin-checkout-ecommerce-gtm-event'
import { ProductKinds } from '@components/payments/payment-selection-view'

export interface SubscriptionContractFormInputs extends Agreements {
  subscription_contract_rules: boolean
}

export const SubscriptionContractView = (): JSX.Element => {
  const { subscription_contract } = useSelector(selectAppDataUrls)

  const { PageWrapper, productDetails: subscriptionContractDetails } = useProductDetails<SubscriptionContractDetails>(
    subscription_contract.details,
  )

  useGtmEcommerceBeginCheckoutGtmEvent(2, ProductKinds.SUBSCRIPTION_CONTRACT, subscriptionContractDetails)

  const methods = useForm<SubscriptionContractFormInputs>()

  const { action: onSubmit, isLoading: isSubmitting } = useFormRequest(
    async (payload: SubscriptionContractFormInputs) => {
      if (!subscriptionContractDetails) return

      if (subscriptionContractDetails.has_rules_to_accept) {
        await commonObjectPost(subscriptionContractDetails.urls.rules, payload)
      }

      const response = await commonObjectPost<{ url: string }>(subscriptionContractDetails.urls.payment, payload)
      document.location.href = response.url
    },
    methods.setError,
  )

  return (
    <PageWrapper>
      <AppHeaderSection images={ResortImages} imageDescription="Holiday Park & Resort Klub">
        <SubscriptionContractHeaderSectionContent subscriptionContractDetails={subscriptionContractDetails} />
      </AppHeaderSection>
      <div className="container app-header-section__after-content">
        {subscriptionContractDetails && (
          <div className="row gx-xl-5">
            <div className="card col-lg-9 col-12 py-4  card-shadow border-0 overflow-hidden">
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <SubscriptionContractDetailsContent subscriptionDetails={subscriptionContractDetails} />
                  {['initial', 'initial_resume'].includes(subscriptionContractDetails.status) && (
                    <>
                      <hr className="mx-n5" />
                      {subscriptionContractDetails.has_rules_to_accept && (
                        <SubscriptionContractDetailsCardAgreements
                          subscriptionContractDetails={subscriptionContractDetails}
                        />
                      )}
                      <PaymentActionRow
                        isLoading={isSubmitting}
                        className="mt-4"
                        amount={subscriptionContractDetails?.single_price_brutto}
                      />
                    </>
                  )}
                </form>
              </FormProvider>
            </div>
          </div>
        )}
      </div>
      <ResortsGallery title="Gdzie wykorzystasz bonusy z Subskrypcji?" />
      <AppFooter />
    </PageWrapper>
  )
}
