import * as React from 'react'
import { SideCard } from '@components/side-card'

interface Props {
  imgSource: string
}

export const GastroGenericSideCard = ({ imgSource }: Props): JSX.Element => (
  <SideCard
    className="d-lg-block d-none w-100 position-relative"
    image={<img src={require(`@assets/images/${imgSource}`)} alt="Kupon gastronomiczny" />}
  />
)
